import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
        {element}
        <Script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer/> 
    </>
  )
}